<template>
  <div>
    <div class="d-flex py-10 relative">
      <v-icon medium class="pl-10 absolute" @click="onClickPrevIcon">
        $vuetify.icons.prevIcon
      </v-icon>
      <span
        class="d-flex justify-center gray900--text font-g14-44 font-weight-700 ma-auto"
        >개인정보처리방침</span
      >
    </div>
    <div>
      <iframe
        :src="url"
        style="display: block; width: 100%; height: 100vh"
        frameborder="0"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterPrivacy',
  data() {
    return {
      url: '',
    }
  },
  created() {
    this.url = this.privacyUrl()
  },
  methods: {
    privacyUrl() {
      //https://intro-alpha.cellook.kr/privacy
      return `https://intro${
        process.env.VUE_APP_ENV === 'production' ? '' : '-alpha'
      }.cellook.kr/privacy`
    },
    onClickPrevIcon() {
      this.$router.back()
    },
  },
}
</script>
<style lang="scss" scoped></style>
